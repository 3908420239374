/* @font-face {
  font-family: primaryFont;
  src: url(fonts/Gilroy-Light.otf),url(fonts/Gilroy-ExtraBold.otf);
  font-weight: normal;
}
@font-face {
  font-family: secondaryFont;
  src: url(fonts/OpenSans-Regular.ttf);
  font-weight: normal;
} */
html {
  height: 100%;
}
body {
  height: 100vh;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  height: 100% !important;
  width: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
}
