.notification-container{
    width:500px !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
}
.notification-header{
    display:flex !important;
    flex-direction:row !important;
    padding:16px 32px !important;
    justify-content:space-between !important;
}
.notification-main{
    width:100% !important;
    display:flex !important;
    justify-content:center !important;
    overflow-y:scroll !important;
}
.list-item-container{
    padding:0px 24px 0px 24px !important
}
.list-date-container{
    margin:0px 0px 12px !important
}
.list-content-container{
    display:flex !important;
    margin:0px 0px 12px !important;
    gap:10px !important
}
.avatar-container{
    display:flex !important; 
    flex-direction:column !important;
}
.text-container{
    display: flex;
    background:#Ec1087 !important;
    padding:16px 24px !important;
    border-radius:10px !important;
    gap:10px !important
}