.Accordion-eventview {
  display: flex;
}

.css-15v22id-MuiAccordionDetails-root {
  padding: 0px !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  padding: 0px !important;
}
/* .css-1jg7ho9-MuiAccordionDetails-root {
  padding: 8px 20px 8px 10px !important;
} */
.css-o4b71y-MuiAccordionSummary-content {
  padding-left: 10px !important;
}
.css-a5rdam-MuiGrid-root {
  margin-left: -5px !important;
}
.MuiBox-root css-19kzrtu {
  height: 74vh !important;
  overflow-y: scroll !important;
}

.css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
  min-height: 35px !important;
  height: 35px !important;
}

.market-group-item-bc {
  display: flex;
  align-items: center;
  border: 1px solid white;
  font-size: 0.8rem;
  cursor: pointer;
  min-height: 24px;
  padding: 4px 10px;
  justify-content:space-between;
}
.market-group-holder-bc{
  display:grid;
  background: #cccccc;
  width: 100%;
}
.market-group-item-bc:hover {
  background-color: #ec1087;
  color: white;
}

.market-group-item-bc:hover .market-odd-bc {
  color: #ffffff;
}

.active {
  background-color: #ec1087;
  color: white !important;
}

.MuiAccordionDetails-root .css-15v22id-MuiAccordionDetails-root {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.market-title-bc {
  color: #000000cc;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  font-size: 12px !important;
  padding: "0px 4px 0px 4px";
}

.market-name-bc {
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  font-size: 12px !important;
}
.marketHeading{
  padding: 10px ;
  font-weight:500 ;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  font-size: 12px !important;
}

.market-odd-bc {
  color: #3a0088;
  font-size: 0.8rem;
  line-height: 15px;
  transition: color 0.24s;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  font-size: 12px !important;
}

.market-odd-bc:hover {
  color: #ffffff;
}

.accordion-market-details{
  padding: 1px !important;
  display: flex !important;
  flex-wrap: wrap !important;
  align-items: center !important;
}

.market-accordion.Mui-expanded {
  margin:0px !important
}