.centered-tabs-box {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-family: "Roboto", Arial, Helvetica, sans-serif !important;
    height: 36px !important
}

.centeredTab {
    font-family: "Roboto", Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
    color: rgba(0, 0, 0, 0.5) !important;
    text-transform: none !important;
    padding: 0px !important;
    background: transparent !important;
    box-sizing: border-box !important;
    letter-spacing: .5px !important;
    /* color: black !important */
    margin: 0px 12px !important;
    font-weight: 500 !important;
    border-bottom: 2px solid transparent !important;
}

.centeredTab:hover {
    border-bottom: 2px solid #EC1087 !important;
    color: black !important;
    padding: 0px !important;
}

.logout-button {
    font-family: "Roboto", Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    color: rgba(0, 0, 0, 0.5) !important;
    padding: 4px 4px !important;
    text-transform: none !important;
    background: transparent !important;
}

.logout-button:hover {
    color: rgba(0, 0, 0) !important;
}

.css-1aquho2-MuiTabs-indicator {
    background-color: #EC1087 !important;
}

/* Production changes */
.centeredTab.Mui-selected {
    color: black !important;
}

.css-ttwr4n {
    background-color: #EC1087 !important;
}

.tabpanel {
    height: 100vh !important;
    overflow: scroll;
}