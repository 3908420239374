.friendsTabs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .friendsTabs-container {
    padding: 2px;
  }
}

/* Friendstabs */

.friends-tabs-wrapper {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.friendsTabs-box {
  background: rgba(0, 0, 0, .15) !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  border-radius: 4px !important;
  height: 36px !important;
}

.friendsTabs {
  font-size: 14px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  color: rgba(0, 0, 0, 0.5) !important;
  padding: 0px 16px !important;
  text-transform: none !important;
  border-bottom: 2px solid transparent !important;
}

.friendsTabs.Mui-selected {
  color: #ffffff !important;
  background-color: #EC1087 !important;
}

.friendsTabs:hover {
  border-bottom: 2px solid #EC1087 !important;
  color: black !important;
  background-color: #ffffff !important;
}

/* UserData */

.userslist {
  height: 80vh !important;
  overflow-y: overlay !important;
  border-radius: 4px !important;
}

.textfield-container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  background: #f2f2f2 !important;
  padding: 10px !important;
  border-radius: 4px !important;
}

.table-heading {
  font-size: 14px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  color: rgb(0, 0, 0);
  font-weight: 600 !important;
}

.table-data {
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: 500 !important;
  padding: 6px !important;
}

.btn {
  background-color: #1c226b !important;
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  padding: 0px 6px !important;
  box-shadow: 0px 0px !important;
}

.btn:hover {
  background-color: #272f93 !important;
}

.MuiTabs-indicator:hover {
  color: black;
}

/* Groups */
.group-container {
  max-width: 100%;
  background: #f2f2f2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px
}

.group-name {
  font-size: 14px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  font-weight: 600 !important;
  padding: 16px 16px 0px !important;
}

.headingText {
  font-size: 14px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  font-weight: 600;
  padding: 10px;
}

.groupsSearch {
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 60%;
  border-radius: 4px !important;
}

.Addbtn {
  background-color: #1c226b !important;
  border-radius: 4px;
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  box-shadow: 0px 0px !important;
}

.Addbtn:hover {
  background-color: #272f93 !important;
}

.group-action {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

/* Media quires  */
@media only screen and (max-width: 600px) {
  .groupsSearch {
    flex: 25%;
  }
}

.addbtn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 5%
}

@media only screen and (min-width: 769px) and (max-width: 900px) {
  .addbtn-container {
    flex: 10%;
  }
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .addbtn-container {
    flex: 20%;
  }
}

@media only screen and (max-width: 600px) {
  .addbtn-container {
    flex: 25%;
    padding: 0px;
  }
}

@media only screen and (max-width: 600px) {
  .group-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px
  }
}

/* AddFriends */

.add-friend-container {
  background: #f2f2f2 !important;
  overflow-y: scroll !important;
}

.search-container {
  display: flex !important;
  flex-direction: row !important;
  text-align: center !important;
  justify-content: center !important;
}

/* NewGroups */

.newGroup-container {
  background: #f2f2f2 !important;
  display: flex !important;
  flex-direction: column !important;
  width: 100% !important;
}

.back-btn {
  width: fit-content;
  display: flex !important;
  align-items: center !important;
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  font-weight: 600 !important;
  padding: 10px;
  cursor: pointer;
}

.back-btn:hover {
  background-color: whitesmoke;
}

.addGroupMember-btn {
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  padding: 0px 6px !important;
  box-shadow: 0px 0px !important;
  color: #ffffff !important;
}

.addGroupMember-btn:hover {
  background-color: #272f93 !important;
}

/* EditGroup */

.editGroup-tabs-box {
  display: flex !important;
  flex-direction: row !important;
  /* background: #1c226b !important; */
  background: rgba(0, 0, 0, 0.15) !important;
  align-items: center !important;
  height: 40px !important;
}

.editGroupTab {

  font-size: 14px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  padding: 0px 24px !important;
}

.editGroupTab:hover {
  border-bottom: 2px solid #EC1087 !important;
  color: black !important;
  background-color: #ffffff !important;

}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  box-shadow: none !important;
  max-width: 100%;
}

.css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop {
  background-color: #0404041a !important;
}

/* Add New Member */
.confirmbtn {
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  padding: 0px 6px !important;
}

/* pagination */
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  background: #f2f2f2;
  padding: 8px 0px;
}

/* Production changes */
.css-uhb5lp {
  box-shadow: none !important;
}

.css-919eu4 {
  background:#0404043d !important;
}