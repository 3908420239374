/* MyChallengeTabs */
.myChallenges-tabs-box{
  background: rgba(0,0,0,.15) !important;
  display:flex !important;
  justify-content:center !important;
  align-items: center !important;
  height:36px !important;
  border-radius: 4px !important;
}

.myChallenges {
  display: flex;
  justify-content: center;
  align-items: center;
}
.myChallengeTab{
  font-size: 14px !important;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  color: rgba(0,0,0,0.5) !important;
  text-transform: none !important;
  padding:0px 16px !important;
  border-bottom: 2px solid transparent !important;
}
.myChallengeTab.Mui-selected{
  color: #ffffff !important;
  background-color: #EC1087 !important;
}
.myChallengeTab:hover{
  border-bottom: 2px solid #EC1087 !important; 
  color: black !important;
  background-color: #ffffff !important;
}
.mychallenges-title {
  background: #1c226b;
  color: white;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px !important;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
}
.myChallenge-wrapper-box{
  width: 80% !important;
}
.myChallenge-tabs-wrapper{
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.myChallenge-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
}
@media only screen and (max-width: 600px) {
  .myChallenge-container {
    padding: 2px;
  }
}
@media (min-width: 1024px) and (max-width: 1250px) {
  .myChallenge-wrapper-box {
    width: 85% !important
       }
  }
  @media (min-width: 600px) and (max-width: 1023px) {
    .myChallenge-wrapper-box {
      width: 95% !important
         }
    }
    @media (max-width: 600px) {
      .myChallenge-wrapper-box {
        width: 100% !important
           }
      }


/* Created challenges, Running challenges, Expired challenges,Taken Challenges, ListofWon and ListofLost */
.challenges-container{
  display: flex; 
  justify-content: center;
}
.challenges-wrapper {
  max-height: 80vh;
  margin: 0 !important;
  padding: 0 !important;
  overflow-y: overlay !important;
  width:100% !important
}
.tableheaders {
  border-bottom: 1px solid #000000;
  background:#f2f2f2;
  height:36px
}
.table-heading{
  font-size: 14px !important;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  color: rgb(0,0,0) !important;
  font-weight: 600 !important;
}
.table-data{
  font-size: 12px !important;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  color: rgba(0,0,0,0.7);
  font-weight: normal !important;
}
.tableRowdetails {
  background: #f2f2f2 !important;
  height:48px !important 
}
.matchVersus {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 20px;
  font-size: 12px !important;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
}
.matchVersus-child{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.matchVs{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.mychallengepagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  background: #f2f2f2;
  padding: 8px 0px;
  /* height:36px */
 
}

@media (max-width:1024px) {
  .table-data {
       padding: 0 !important;
       }
  }

  /* Active Challenges */
  .btn{
    background-color: #1c226b !important;
    font-size: 12px !important;
    font-family: "Roboto",Arial,Helvetica,sans-serif!important;
    padding: 0px 6px !important;
    box-shadow: 0px 0px !important;
}
.btn:hover{
  background-color: #272f93 !important;
}