.leaderboardTabs-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding:10px;
  width:100%;

}
.leaderBoard-tabs-wrapper{
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
@media only screen and (max-width: 600px){
  .leaderboardTabs-container{
    padding:2px;         
  }
}
.Leaderslist{
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  min-height:200px;
  height:86vh;
  width: 100%;
}
@media only screen and (max-height: 500px){
  .Leaderslist{
    height: 81vh       
  }
}
@media only screen and (max-height: 400px){
  .Leaderslist{
    height: 75vh      
  }
}
@media only screen and (max-height: 250px){
  .Leaderslist{
    height: 50vh      
  }
}

.leaderboard-tabs-box{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  font-size: 16px !important;
  height: 36px !important;
  width: 100% !important;
}
.leaderboardTabs{
  font-size: 14px !important;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  align-self: center !important;
  color:rgba(0,0,0,0.5) !important;
  padding: 4px 8px !important;
  text-transform: none !important;
  border-bottom: 2px solid transparent !important;
}
.leaderboardTabs.Mui-selected{
  color: #ffffff !important;
  background-color: #EC1087 !important;
}
.leaderboardTabs:hover{
  border-bottom: 2px solid #EC1087 !important; 
  color: black !important;
  background-color: #ffffff !important;
 
}
.leaderboard-top3 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width:100%;
  margin:10px 0px;
  flex:"30%";
  min-height: 100px;
}
.leaderboardtop {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.leaderboardname {
  font-size: 14px !important;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  font-weight: 600 !important;
  margin-bottom: 10px !important;
}

.table-heading{
  font-size: 14px !important;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  font-weight: 600 !important;
}
.table-data{
  font-size: 12px !important;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  
}
/* 
.leaderboardTabs.Mui-selected{
  color: rgb(0,0,0) !important;
} */
/* .leaderboardTabs.MuiTabs-indicator{
  background-color: pink !important;
} */

.css-sghohy-MuiButtonBase-root-MuiButton-root{
  /* background-color: #ec1087 !important ; */
  color:white !important ;
  font-size: 12px !important;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
}
.Addbtn{
  background-color: #1c226b !important;
  border-radius: 4px;
  font-size: 12px !important;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
}
.btn{
  background-color: #1c226b !important;
  font-size: 12px !important;
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  padding: 0px 6px !important;
}
/* .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.friendsTabs.Mui-selected{
  color: rgb(0,0,0) !important;
} */
.user-search{
  margin: 0px 10px;
}
.userslist{
  height: 80vh !important; 
  overflow-y: overlay !important;
  border-radius: 4px !important;
}
.textfield-container{
  display: flex !important;
  flex-direction: row !important; 
  justify-content: center !important; 
  background: #f2f2f2 !important; 
  padding: 10px !important;
  border-radius: 4px !important;
}
/* pagination */
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  background: #f2f2f2;
  padding: 8px 0px;
  /* flex: 5%; */
}