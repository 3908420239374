/* ChallengeModelTabs */
.challengeModel-tabs-box {
  display: flex !important;
  flex-direction: row !important;
  background: rgba(0, 0, 0, 0.15) !important;
  align-items: center !important;
  height: 40px !important;
}

.challengeModelTab {
  font-size: 14px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  padding: 0px 24px !important;
  border-bottom: 2px solid transparent !important;
  text-align: center !important;
}

.challengeModelTab:hover {
  border-bottom: 2px solid #EC1087 !important;
  color: black !important;
  background-color: #ffffff !important;

}

.ChallengeAFriendRoot {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px !important;
  height: 100%;
}

.ChallengeAFriend {
  display: flex ;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.ChallengeHeaders {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: #00000066;
  color: #ffffff;
  height: 50px;
}

.ChallengedSport {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  font-size: 15px;
  margin: 12px;
}

.ChallengedVersus {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  font-size: 14px;
  margin: 15px 5px !important;
}

.ChallengeBetslip {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: 20px 0px !important;
}

.ChallengeButtons {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  margin: 4px 10px 22px !important;
  flex-grow: 1;
}
/* @media screen and (max-height:400px){
  .ChallengeButtons{
    flex-grow: 2;
  }
} */
.challenge-btn {
  font-size: 14px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  padding: 8px !important;
  box-shadow: 0px 0px !important;
}

.challenge-btn:hover {
  background-color: #272f93 !important;
}

.Expirationdatetime {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.matchexpire-text {
  color: rgba(0, 0, 0, .9) !important;
  font-size: 11px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  line-height: 16px !important;
}

.matchexpire-container {
  display: flex !important;
  align-items: center !important;
  min-height: 30px !important;
  padding: 4px 4px !important;
  background: rgba(170, 127, 0, 0.2) !important;
  border-radius: 4px !important;
  margin-top: 6px !important;
  gap: 4px !important
}

.Betslipteamsnames {
  color: #000000b3;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  font-size: 12px;
}

.BetslipMarketName {
  color: #000000a6;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  font-size: 12px;
  text-align: left;
  width: 100%;
}

.BetslipMatchDatetimechallengemode {
  width: 100%;
  color: #00000066;
  font-size: 11px;
  text-align: left;
  padding: 2px 0px;
}

/* Challenge model Users Tab */
.users-container {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  /* background: #f2f2f2 !important; */

}

.userTabs {
  font-size: 14px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  color: rgba(0, 0, 0, 0.5) !important;
  padding: 0px 24px !important;
  border-bottom: 2px solid transparent !important;
  text-align: center !important;
  text-transform: none !important;
}

.userTabs.Mui-selected {
  color: #ffffff !important;
  background-color: #EC1087 !important;
}

.userTabs:hover {
  border-bottom: 2px solid #EC1087 !important;
  color: black !important;
  background-color: #ffffff !important;
}

/* UserData */

.textfield-container {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  background: #f2f2f2 !important;
  padding: 10px !important;
  border-radius: 4px !important;
}

.table-heading {
  font-size: 14px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  color: rgb(0, 0, 0);
  font-weight: 600 !important;
}

.table-data {
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  color: rgba(0, 0, 0, 0.7) !important;
  font-weight: 600 !important;
}

.btn {
  background-color: #1c226b !important;
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  padding: 0px 6px !important;
}

/* Groups */
.group-container {
  max-width: 100%;
  background: #f2f2f2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px
}

.groupsSearch {
  margin: 0px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 60%;
  border-radius: 4px !important;
}

/* Search User */
.searchBtn {
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  padding: 0px 6px !important;
}

.css-1oqqzyl-MuiContainer-root {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

/* pagination */
.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  background: #f2f2f2;
  padding: 8px 0px;
}

.tabpanel-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 100% !important
}

@media screen and (max-width:768px) {
  .ChallengeButtons {
    gap: 10px;
  }
}

@media screen and (max-width:768px) {
  .challenge-btn {
    font-size: 12px !important;
  }
}

/* .challengeModal-dialog.MuiDialog-container{
  width:70% !important;
  height: 60vh !important;
}
.css-1t1j96h-challengeModal-dialog-MuiPaper-root-MuiDialog-paper{
  width:70% !important;
  height: 60vh !important;
  max-width: 500px!important;
} */