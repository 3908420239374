.sportsTitle {
  text-align: center;
  background-color: #ec1087;
  color: #ffffff;
  border-radius: 5px;
  padding: 8px;
  font-size: 14px;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}

@media screen and (max-width: 768px) {
  .sportsTitle {
    font-family: "Roboto", Arial, Helvetica, sans-serif !important;
    font-size: 14px;
    font-weight: 500;
  }
}
.sports-list-container {
  overflow-y: overlay;
  position: relative;
  max-height: 87%;
  will-change: scroll-position;
}
@media screen and (max-height: 500px) {
  .sports-list-container {
    height: 85%;
  }
}

@media screen and (max-height: 400px) {
  .sports-list-container {
    height: 80%;
  }
}
@media screen and (max-height: 300px) {
  .sports-list-container {
    height: 75%;
  }
}
@media screen and (max-height: 250px) {
  .sports-list-container {
    height: 70%;
  }
}
.sports-accordion.Mui-expanded {
  margin: 3px !important;
}
.sports-accordion {
  box-shadow: none !important;
}

.sports-accordion:before {
  height: 0px !important;
}
::-webkit-scrollbar {
  width: 4px;
  height: 3px;
  border-radius: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #727272;
  border-radius: 4px;
}

/* Production code */
.css-1iji0d4.Mui-expanded {
  min-height: 35px !important;
  height: 35px !important;
  padding: 0px 10px !important;
}

.css-1iji0d4 {
  min-height: 35px !important;
  height: 35px !important;
  padding: 0px 10px !important;
}
