.betslip-container {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}

.betSlipTitle {
  font-size: 14px;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  background: rgb(236, 16, 135);
  color: #ffffff;
  padding: 12px;
  text-align: center;
  border-radius: 4px !important;
}

@media screen and (max-width: 768px) {
  .betSlipTitle {
    font-size: 14px !important;
    font-family: "Roboto", Arial, Helvetica, sans-serif !important;
    font-weight: 500;
  }
}

.BetslipMatchbtw {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  font-family: "Roboto";
}

.Betslip {
  padding: 6px 8px;
  background: transparent;
  margin-top: 5px;
  font-size: 14px !important;
  border-radius: 5px;
}

.Betslip-insertOdd {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  margin: 5;
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}

.betslipbtn {
  display: flex;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.05);
  /* border-radius: 4px; */
  justify-content: center;
  align-items: center;
  width: 100% !important;
}

.betslip-button {
  margin: 10px 0px !important;
  box-shadow: 0px 0px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}

.active-button {
  width: 70% !important;
  margin: 10px 0px !important;
  box-shadow: 0px 0px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}

.active-button:hover {
  background: #272f93 !important;
}

.BetslipMatchDatetime {
  width: 100%;
  margin-top: 3px;
  color: rgba(0, 0, 0, 0.4);
  font-size: 11px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  text-align: left;
  line-height: 13px;
}

.BetslipMatchOdds {
  margin-top: 3;
}

.EmptyBetslip {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5vh 10px;
  text-align: center;
  font-size: 16px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  line-height: 18px;
  color: #000000b3;
}

.BetslipStakeField {
  display: flex;
  justify-content: center;
  background-color: #0000001a;
  border-radius: 5px;
}

.BetslipBody {
  background-color: #ffffff;
  position: relative;
  height: 86%;
  overflow-y: overlay;
  padding-right: 5px;
}

/* @media screen and (max-height: 500px) {
  .BetslipBody {
    height: 75%;
  }
}

@media screen and (max-height: 400px) {
  .BetslipBody {
    height: 65%;
  }
}

@media screen and (max-height: 300px) {
  .BetslipBody {
    height: 75%;
  }
}

@media screen and (max-height: 250px) {
  .BetslipBody {
    height: 50%;
  }
} */

.Betslipteamsnames {
  color: rgba(0, 0, 0, 0.7);
  font-size: 11px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  line-height: 14px !important;
  flex: 85% !important;
  text-align: left !important;
  flex-grow: 1 !important;
}

.BetslipMarketName {
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  background: transparent !important;
  line-height: 16px !important;
}

.BetslipEventName {
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  line-height: 16px !important;
}

.BetslipEventPrice {
  color: #3a0088 !important;
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}

.BetslipPossiblewin {
  color: rgba(0, 0, 0, 0.95) !important;
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}

.BetslipWinFrw {
  color: #1c226b !important;
  font-size: 12px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  font-weight: 700 !important;
}

.betSlipTooltip {
  font-size: 11px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}

.betSlipEmpty {
  margin: 5vh;
  text-align: center;
  color: rgba(0, 0, 0, 0.7);
  font-size: 16px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}

.minStake {
  font-size: 11px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
}

.stakeBox {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
  padding: 10px 0px !important;
  border-radius: 4px !important;
  margin-bottom: 10px !important;
  width: 100% !important;
}

.minimumStake-error {
  display: flex !important;
  align-items: center !important;
  min-height: 34px !important;
  padding: 4px 10px !important;
  background: rgba(170, 127, 0, 0.2) !important;
  border-radius: 4px !important;
  margin-bottom: 10px !important;
  gap: 4px !important;
}

.info-text {
  color: rgba(0, 0, 0, 0.9) !important;
  font-size: 11px !important;
  font-family: "Roboto", Arial, Helvetica, sans-serif !important;
  line-height: 16px !important;
}

.betslipSameMarket-error {
  display: flex !important;
  align-items: center !important;
  min-height: 34px !important;
  margin: 4px 0px !important;
  background: rgba(255, 52, 73, 0.2) !important;
  border-radius: 4px !important;
  margin-bottom: 10px !important;
  gap: 4px !important;
}

.staketextfield {
  user-select: none;
}

.staketextfield :hover {
  border-bottom: none !important;
}

.css-nz481w-MuiInputBase-input-MuiInput-input.Mui-disabled {
  -webkit-text-fill-color: none !important;
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
  border-bottom: none !important;
}

/* productionstyles */
.css-1vv4lmi::after {
  border-bottom: none !important;
}

.css-2duac4.Mui-disabled {
  -webkit-text-fill-color: none !important;
}

/*

@keyframes blinkMe {
  0% {
   opacity: 0;
  }
  50% {
   opacity: 1;
  }
  100% {
   opacity: 0;
  }
 } */

/* .dialog.MuiDialog-paper{
    height: 85vh; 
}
@media screen and (max-height:699px){
  .dialog.MuiDialog-paper{
    height:80vh;
  }
}
@media screen and (min-height:700px){
  .dialog.MuiDialog-paper{
    height:90vh;
  }
} */
