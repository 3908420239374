.matchdataconatainer{
  overflow-y: overlay;
  position: relative;
  height:95%;
  will-change: scroll-position;
  padding: 0px 4px;
  box-sizing: border-box;
}
@media screen and (max-height:500px){
  .matchdataconatainer{
    height: 95%;
  }
}
@media screen and (max-height:400px){
  .matchdataconatainer{
    height:88%;
  }
}
@media screen and (max-height:300px){
  .matchdataconatainer{
    height:85%;
  }
}
@media screen and (max-height:250px){
  .matchdataconatainer{
    height:80%;
  }
}
.competition-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #00000026;
  height: 34px;
  padding: 0 10px;
  cursor: pointer;
  transition: "background 0.24s";
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  font-size: 12px !important;
}
.competition-details-header:hover{
  background: #bfbfbf !important;
}
.item-eventview {
  display: flex;
  align-items: center;
  height: 36px;
  margin-bottom: 1px;
  border-radius: 4px;
  position: relative;
  padding: 0px 10px;
  border-radius: 10;
  color: #000000e6;
  background: #0000001a;
  cursor: "pointer";
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  font-size: 13px !important;
}


/* .css-heg063-MuiTabs-flexContainer {
  overflow-x: overlay;
} */
/* .competition-details-header:hover {
  background-color: inherit;
} */

/* .market-group-item-bc {
  border: 1px solid white;
  padding: 4px 10px;
  font-size: 13px;
}

.market-group-item-bc:hover {
    background-color: #ec1087;
    color: white;
  } */

/* .market-group-holder-bc :hover {
    background-color: #ec1087;
    color: white;
  } */

/* .market-group-holder-bc {
  display: flex;
  justify-content: space-around;
  align-items: center;
} */

.teamnames{
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  font-size: 12px !important;
  color: rgb(0,0,0) !important
}
.matchtime{
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  font-size: 11px !important;
  color: rgba(0,0,0,0.5) !important
}
.versus{
  font-family: "Roboto",Arial,Helvetica,sans-serif!important;
  font-size: 11px !important;
  color: rgba(0,0,0,0.5) !important
}


/* MarketTypeTabs*/
.marketTabs-box{
  background: #0000001a !important;
  display:flex !important;
  align-items: center !important;
  border-radius:4px !important;
  height:36px !important;
}
.marketTabs{
    font-size: 14px !important;
    font-family: "Roboto",Arial,Helvetica,sans-serif!important;
    color: rgba(0,0,0,0.5) !important;
    padding:0px 16px !important;
    text-transform: none !important;
    border-bottom: 1px solid transparent !important;
}
.marketTabs.Mui-selected{
  color: black !important;
}