.slider {
  position: relative;
  width: 100%;
  height: 310px;
  overflow: hidden;
}

.slider a.previousButton,
.slider a.nextButton {
  width: 25px;
  height: 25px;
  line-height: 0;
  display: block;
  position: absolute;
  top: 55%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  z-index: 1;
  color: #333;
  /* padding: 10px; */
  text-decoration: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  /* prevent jump effect when scaling */
}

.previousButton {
  background: url("../../Assets//arrow_back_ios_new.svg") no-repeat center
    center !important;
}

.nextButton {
  background: url("../../Assets/arrow_forward_ios.svg") no-repeat center center !important;
}

.previousButton svg,
.nextButton svg {
  display: none;
}

.slider a.previousButton:not(.disabled):hover,
.slider a.nextButton:not(.disabled):hover {
  -webkit-transform: translateY(-50%) scale(1.5);
  transform: translateY(-50%) scale(1.5);
  cursor: pointer;
}

.slider a.previousButton {
  left: 1%;
}

.slider a.nextButton {
  right: 1%;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.slide.hidden {
  visibility: hidden;
}

.slide.previous {
  left: -100%;
}

.slide.current {
  left: 0;
}

.slide.next {
  left: 100%;
}

.slide.animateIn,
.slide.animateOut {
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.slide.animateIn.previous,
.slide.animateIn.next {
  left: 0;
  visibility: visible;
}

.slide.animateOut.previous {
  left: 100%;
}

.slide.animateOut.next {
  left: -100%;
}

@media (min-width: 1201px) and (max-width: 1350px) {
  .slider {
    height: 290px !important;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .page-number {
    bottom: 6% !important;
    width: 4vw !important;
    right: 1% !important;
  }
  .slider {
    height: 280px !important;
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .page-number {
    width: 4vw !important;
    right: 1% !important;
  }
  .slider {
    height: 232px !important;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .caraousel-container {
    padding-top: 2% !important;
  }
  .nextButton {
    right: 2% !important;
  }
  .previousButton {
    left: 2% !important;
  }
  .page-number {
    bottom: 6% !important;
    right: 2% !important;
    width: 6vw !important;
  }
  .slider {
    height: 178px !important;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .caraousel-container {
    padding-top: 2% !important;
  }
  .page-number {
    bottom: 6% !important;
    right: 1% !important;
    width: 8vw !important;
    height: 13px !important;
  }
  .slider {
    height: 103px !important;
  }
}
@media (min-width: 300px) and (max-width: 350px) {
  .page-number {
    bottom: 6% !important;
    right: 1% !important;
    width: 9vw !important;
    height: 13px !important;
  }
  .slider {
    height: 85px !important;
  }
}

@media (min-width:300px) and (max-width:600px){
  .caraousel-container{
    padding-top: 2% !important;
  }
}
